import { axiosBackend } from './axios'

interface IUser {
  name: string;
  email: string;
  phonenumber: string;
}

interface IOrder {
  forellen: number;
  makrelen: number;
}

export async function sendOrderMail (form: any): Promise<void> {
  const fullName = `${form.foreName} ${form.lastName}`;
  const params = {
    templateId: 1,
    sender: {
      name: fullName,
      email: form.email
    },
    subject: `Kontaktanfrage von ${fullName}`,
    params: {
      name: fullName,
      email: form.email,
      phonenumber: form.phoneNumber,
      type: form.type,
      material: form.material,
      subject: form.subject,
      text: form.text
    },
    messageVersions: [
      {
        to: [
          {
            name: 'Reparatur Schmitt',
            email: 'info@reparatur-schmitt.de'
          }
        ]
      }
    ]
  }

  await axiosBackend.post('/smtp/email', params)
}
