export enum Materials {
    ALUMINIUM = 'Aluminium',
    STAHL = 'Stahl',
    KUNSTSTOFF = 'Kunststoff',
    HOLZ = 'Holz'
}

export enum Types {
    TÜREN = 'Tür(en)',
    FENSTER = 'Fenster',
    TÜRENUNDFENSTER = 'Tür(en) und Fenster'
}