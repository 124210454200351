<template>
  <div class="section">
    <div class="container content fadeIn">
      <div class="row">
        <div class="col-12 text-center">
          <h2>Dienstleistungen</h2>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-6 pr-4">
          <div class="d-flex flex-column align-items-center">
            <h3 class="pb-3">Reparatur von Fenster & Türen</h3>
            <div>
              <p class="text-center">
                Wir reparieren Fenster und Türen aus Stahl, Aluminium,
                Kunststoff oder Holz.
              </p>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  Eneuerung und Reparatur von Schlösser & Beschlägen
                </li>
                <li class="list-group-item">Verzogene Türen richten</li>
                <li class="list-group-item">Hängende Fenster neu verklotzen</li>
                <li class="list-group-item">Dichtungenserneuerung</li>
                <li class="list-group-item">Befestigung von losen Rahmen</li>
                <li class="list-group-item">und mehr...</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6 pl-4">
          <div class="d-flex flex-column">
            <h3 class="pb-3 text-center">Wartungsumfang</h3>
            <h5>Fenster</h5>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Beschlagseinstellungen</li>
              <li class="list-group-item">Gängigkeit wiederherstellen</li>
              <li class="list-group-item">
                Schmierarbeiten von beweglichen Teilen
              </li>
              <li class="list-group-item">Dichtungen prüfen/pflegen</li>
              <li class="list-group-item">Anpressdruck prüfen</li>
            </ul>
            <h5 class="pt-3">Türen</h5>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Schloß & Verrigelungen einstellen</li>
              <li class="list-group-item">Türbänder einstellen</li>
              <li class="list-group-item">
                Bewegliche Teile & Kontaktflächen schmieren
              </li>
              <li class="list-group-item">(Boden-)Dichtungen prüfen/pflegen</li>
              <li class="list-group-item">Anpressdruck prüfen</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>