<template>
  <div class="section fullScreenSection">
    <div class="fullScreenImage">
      <div class="text-center title fadeIn pb-lg-3">
        <h2>Fenster und Türen</h2>
        <p class="mb-0 px-3">
          Ihr Spezialist für Instandsetzung und Reparatur von Fenstern und Türen im Landkreis Schweinfurt und Bad Kissingen
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.fullScreenSection {

  .fullScreenImage {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // Bild
    background-image: url("@/assets/main.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .title {
      color: whitesmoke;
      font-family: "Source Sans Pro", sans-serif;
      text-shadow: 0 0 5px #333;
      h2 {
        font-weight: bold;
        font-size: 3.5em;
        line-height: 1.2;
      }
      p {
        font-size: 1.75em;
      }
    }
  }
}
</style>