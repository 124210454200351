<template>
  <footer id="footer" class="py-3">
    <div class="container pt-1">
        <div class="row justify-content-center pb-3">
          <div class="col">
            <router-link :to="{ name: 'Privacy' }" class="pr-3">Datenschutz</router-link>
            <router-link :to="{ name: 'Imprint' }" class="pl-3">Impressum</router-link>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <small>&copy;{{ currentYear }} <strong>Reparatur Schmitt</strong></small>
          </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  },

  mounted () {
    this.$emit('footerLoaded', document.getElementById('footer').offsetHeight)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

footer {
  // position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #e9e9e9;
  // box-shadow: 0px 0px 4px 2px #dadada;
  .row {
    div {
      text-align: center;
    }
  }
  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #333;
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-up(xl) {
  footer {
    position: absolute;
  }
}
</style>
