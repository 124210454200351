import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://api.sendinblue.com/v3',
  headers: {
    'content-type': 'application/json',
    'api-key': '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'
  },
  method: 'post'
})

export const axiosBackend = instance

// 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
