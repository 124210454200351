import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import IndexView from '@/views/Index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    // meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/',
    name: 'Index',
    component: IndexView
  },
  {
    path: '/',
    name: 'Imprint',
    component: IndexView
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/Privacy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (to.name === 'Index' && to.hash === '') {
    return { hash: '#page1' }
  }
})

export default router
