<template>
  <div class="contact py-3" :style="`bottom: ${footerHeight}px`">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-12 text-center">
                <span>
                    <strong>E-Mail:</strong> 
                    info@reparatur-schmitt.de
                </span>
            </div>
            <!-- <div class="col-12 col-md-6 pb-2 pb-md-0 pr-md-4 text-center">
                <span>
                    <strong>E-Mail:</strong> 
                    info@reparatur-schmitt.de
                </span>
            </div> -->
            <!-- <div class="col-12 col-md-6 text-center">
                <span>
                    <strong>Telefon:</strong> 
                    0171/3177854
                </span>
            </div> -->
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        footerHeight: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

.contact {
  width: 100%;
  background-color: #f3f3f3;
  box-shadow: 0px 0px 4px 2px #ececec;
}

@include media-breakpoint-up(xl) {
  .contact {
    position: absolute;
  }
}
</style>