<template>
  <div class="section">
    <div class="container content fadeIn">
      <div class="row pb-3">
        <div class="col text-center">
          <h2>Kontakt</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- Kontaktanfrage abgesendet -->
          <email-was-sent 
            v-if="wasEmailSent"
            :title="'E-Mail wurde versendet!'" />

          <!-- Kontaktformular -->
          <form
            v-if="!wasEmailSent"
            @submit.prevent="sendMail">
            <div class="form-row">
              <div class="form-group col-6">
                <label for="doorsOrWindows">Türen oder Fenster</label>
                <select v-model="form.type" id="doorsOrWindows" class="form-control">
                  <option :value="void 0" selected>Auswählen...</option>
                  <option :value="Types.TÜREN">Tür(en)</option>
                  <option :value="Types.FENSTER">Fenster</option>
                  <option :value="Types.TÜRENUNDFENSTER">Tür(en) und Fenster</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label for="material">Material</label>
                <select v-model="form.material" id="material" class="form-control">
                  <option :value="void 0" selected>Auswählen...</option>
                  <option :value="Materials.ALUMINIUM">Aluminium</option>
                  <option :value="Materials.STAHL">Stahl</option>
                  <option :value="Materials.KUNSTSTOFF">Kunststoff</option>
                  <option :value="Materials.HOLZ">Holz</option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-6">
                <label for="foreName">Vorname</label>
                <input v-model="form.foreName" type="text" class="form-control" id="foreName">
              </div>
              <div class="form-group col-6">
                <label for="lastName">Nachname</label>
                <input v-model="form.lastName" type="text" class="form-control" id="lastName">
              </div>
              <div class="form-group col-6">
                <label for="phoneNumber">Telefonnummer</label>
                <input v-model="form.phoneNumber" type="phone" class="form-control" id="phoneNumber">
              </div>
              <div class="form-group col-6">
                <label for="email">E-Mail</label>
                <input v-model="form.email" type="email" class="form-control" id="email">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="subject">Betreff/Thema</label>
                <input v-model="form.subject" type="text" class="form-control" id="subject">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="text">Anliegen</label>
                <textarea v-model="form.text" class="form-control" id="text" rows="4"></textarea>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <button :disabled="emailAlreadySent" type="submit" class="btn btn-secondary">
                Senden
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <Contact :footer-height="footerHeight" />

    <Footer
      @footerLoaded="(value) => footerHeight = value"
    />
  </div>
</template>

<script>
import Contact from '@/components/Contact.vue';
import Footer from '@/components/Footer.vue';
import EmailWasSent from '@/components/EmailWasSent.vue';
import { Materials, Types } from '@/scripts/interfaces';
import { sendOrderMail } from '@/scripts/sendMail';
import Cookies from 'js-cookie'

export default {
  components: {
    Contact,
    Footer,
    EmailWasSent
  },

  data () {
    return {
      Materials,
      Types,
      footerHeight: 0,
      form: {},
      wasEmailSent: false,
      isLoading: false,
      emailAlreadySent: false,
    }
  },
  mounted () {
    this.emailAlreadySent = this.getAlreadySent()
  },
  methods: {
    async sendMail () {
      try {
        if (this.emailAlreadySent === true) return // Email darf nur alle 30 Minuten gesendet werden
        this.isLoading = true
        await sendOrderMail(this.form)
        Cookies.set('alreadySent', 'true', { expires: 1/48 })
        this.isLoading = false
        this.wasEmailSent = true
        this.emailAlreadySent = true
      } catch (e) {
        
      }
    },
    getAlreadySent () {
      const alreadySent = Cookies.get('alreadySent')
      return JSON.parse((alreadySent == undefined ? 'false' : alreadySent))
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

textarea {
  resize: none;
}
</style>