<template>
  <!-- Mobile -->
  <nav class="navbar d-lg-none navbar-expand-lg navbar-light fixed-top justify-content-center justify-content-sm-between w-100">
    <!-- Für Index Anchor -->
    <a v-if="$route.name === 'Index'" class="navbar-brand" href="#page1">
      <img
        src="@/assets/logo.png"
        alt=""
        width="330"
        @load="setNavbarHeight"
      />
    </a>
    <a v-else class="navbar-brand" @click="$router.push({ name: 'Index', hash: '#page1' })">
      <img
        src="@/assets/logo.png"
        alt=""
        width="330"
        @load="setNavbarHeight"
      />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#anchorTags" aria-controls="anchorTags" aria-expanded="false" aria-label="Menü ausklappen">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse ml-4" id="anchorTags">
      <ul class="navbar-nav ml-auto my-3 text-left">
        <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '#page1' }">
          <a v-if="$route.name === 'Index'" class="nav-link" href="#page1">Start</a>
          <a v-else class="nav-link" @click="$router.push({ name: 'Index', hash: '#page1' })">Start</a>
        </li>
        <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '#page2' }">
          <a v-if="$route.name === 'Index'" class="nav-link" href="#page2">Dienstleistungen</a>
          <a v-else class="nav-link" @click="$router.push({ name: 'Index', hash: '#page2' })">Dienstleistungen</a>
        </li>
        <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '#page3' }">
          <a v-if="$route.name === 'Index'" class="nav-link" @click="setLink('#page3')">Kontakt</a>
          <a v-else class="nav-link" @click="$router.push({ name: 'Index', hash: '#page3' })">Kontakt</a>
        </li>
      </ul>
    </div>
  </nav>

  <!-- Desktop -->
  <nav class="navbar d-none d-lg-flex navbar-expand-lg navbar-light fixed-top w-100">
    <div class="container">
      <div class="row">
        <div class="col d-flex align-items-center">
          <!-- Für Index Anchor -->
          <a v-if="$route.name === 'Index'" class="navbar-brand" href="#page1">
            <img
              src="@/assets/logo.png"
              alt=""
              width="330"
              @load="setNavbarHeight"
            />
          </a>
          <a v-else class="navbar-brand" @click="$router.push({ name: 'Index', hash: '#page1' })">
            <img
              src="@/assets/logo.png"
              alt=""
              width="330"
              @load="setNavbarHeight"
            />
          </a>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#anchorTags" aria-controls="anchorTags" aria-expanded="false" aria-label="Menü ausklappen">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse ml-3 ml-lg-5 pt-lg-2" id="anchorTags">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '#page1' }">
                <a v-if="$route.name === 'Index'" class="nav-link" href="#page1">Start</a>
                <a v-else class="nav-link" @click="$router.push({ name: 'Index', hash: '#page1' })">Start</a>
              </li>
              <li class="nav-item px-lg-5" :class="{ 'active': $route.name === 'Index' && $route.hash === '#page2' }">
                <a v-if="$route.name === 'Index'" class="nav-link" href="#page2">Dienstleistungen</a>
                <a v-else class="nav-link" @click="$router.push({ name: 'Index', hash: '#page2' })">Dienstleistungen</a>
              </li>
              <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '#page3' }">
                <a v-if="$route.name === 'Index'" class="nav-link" href="#page3">Kontakt</a>
                <a v-else class="nav-link" @click="$router.push({ name: 'Index', hash: '#page3' })">Kontakt</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  emits: ['setNavbarHeight'],
  mounted () {
    document.addEventListener('resize', this.setNavbarHeight)
  },
  methods: {
    setNavbarHeight () {
      const navbars = document.getElementsByClassName('navbar')
      const height = navbars[0].offsetHeight > 0 ? navbars[0].offsetHeight : navbars[1].offsetHeight
      document.documentElement.style.setProperty('--sectionPaddingTop', `${height}px`)
      this.$emit('setNavbarHeight', `${height}px`)
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #e9e9e9;
  box-shadow: 0px 0px 4px 2px #ececec;

  .nav-link {
    line-height: normal;
  }
}
</style>