<template>
  <full-page
    v-if="options"
    ref="fullpage"
    :options="options"
    id="fullpage"
  >
    <first-page />
    <second-page />
    <last-page />
  </full-page>
</template>

<script>
import FirstPage from "./pages/FirstPage.vue";
import SecondPage from "./pages/SecondPage.vue";
import LastPage from "./pages/LastPage.vue";

export default {
  components: {
    FirstPage,
    SecondPage,
    LastPage,
  },
  props: {
    navbarHeight: {
      type: String,
      default: void 0,
    },
  },

  computed: {
    options() {
      if (!this.navbarHeight) return void 0;
      return {
        licenseKey: "VK2HI-017SK-K2PNH-JO276-XPXXO",
        // menu: '#fullpage',
        anchors: ["page1", "page2", "page3"],
        dragAndMove: true,
        paddingTop: this.navbarHeight,
        credits: false
        // onLeave: this.onLeave,
        // sectionsColor: ['#f5f5f5'],
      };
    },
  },
};
</script>

<style lang="scss">
#fullpage {
  .section {
    &.active {
      .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: 0.25s;
        -webkit-animation-delay: 0.25s;
      }
    }
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// #fullpage {
// .section {
// padding-top: var(--sectionPaddingTop);
// }
// }
</style>